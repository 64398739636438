import { useContext, useEffect, useState } from "react";
import { models, Report, Embed } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import { EmbeddedReportDTO, ExternalUserSite, PowerBIReport, ServiceBusEnum, ServiceMessage } from "../../api-client-nswag/taxportal-client";
import "./PowerBIReport.scss";
import { OidcIdentityContext } from "@rsmus/react-auth/dist/OidcIdentity";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { snackbarState } from "../../cui/commonComponents/CustomSnackbar";
import UnAuthPowerBi from "../../cui/commonComponents/UnAuthPowerBi";
import { getNewAccessToken, timeOutForRefreshToken } from "../../util/Utils";
import { userPermissions } from "../../App";
import SyncIcon from "@mui/icons-material/Sync";
import React from "react";

export const GlobalTaxInformation = () => {
  const [report, setReport] = useState<Report | null>(null);
  const { user } = useContext(OidcIdentityContext);
  const setSnackbar = useSetRecoilState(snackbarState);
  const userPermission = useRecoilValue(userPermissions);
  const [flag, setFlag] = useState<boolean | null>(null);

  const [tokenTimeout, setTokenTimeout] = useState<any>(null);
  const [tokenExpiration, setTokenExpiration] = useState<Date | null>(null);

  const [reportConfigDTO, setReportConfig] = useState<models.IReportEmbedConfiguration>({
    type: "report",
    embedUrl: "",
    tokenType: models.TokenType.Embed,
    accessToken: "",
    settings: undefined,
  });


  useEffect(() => {
      loadReportConfig();
  }, []);


  const loadReportConfig = async () => {
      await taxPortalClientApi
        .reports_GetEmbedReport(PowerBIReport.GLOBAL_TAX_INFORMATION, userPermission)
        .then((res) => {
          const settings = {
            type: "report",
            embedUrl: res?.embedUrl,
            accessToken: res?.token,
            settings: {
              filterPaneEnabled: false,
              navContentPaneEnabled: false,
            },
          };

          setReportConfig(settings);
          setFlag(false);
          const expiresIn = new Date(res.expiration as Date);
          expiresIn.setMinutes(expiresIn.getMinutes() - 2);
          setTokenExpiration(expiresIn);
        })
        .catch((err) => {
          if (err.status === 401) {
            setFlag(true);
          }
        });
  };


  const refreshAccessToken = async () => {
    if (report) {
      const res = await getNewAccessToken(PowerBIReport.GLOBAL_TAX_INFORMATION);
      if (res && res?.token !== null) {
        const newToken = res.token;
        report.setAccessToken(newToken as string);

        const expiresIn = new Date();
        expiresIn.setHours(expiresIn.getHours() + 1);
        setTokenExpiration(expiresIn);

        if (tokenTimeout) {
          clearTimeout(tokenTimeout);
        }

        const currentTime = new Date();
        const refreshTime = expiresIn.getTime() - currentTime.getTime() - 2 * 60 * 1000;
        if (refreshTime > 0) {
          setTokenTimeout(setTimeout(refreshAccessToken, refreshTime));
        }
      }
    } else {
      console.error("Failed to refresh token.");
    }
  };

  const syncIconClick = async () => {
    try {
        let selectedSyncData: ServiceMessage[] = [];
          const siteData = [JSON.parse(
            `{ "siteId": null, "userName" :"${user?.profile.preferred_username as string}" , "eventType" : ${
              ServiceBusEnum.GLOBAL_TAX_SYNC
            }, "mdmClientId":null }`
          )];
          selectedSyncData = siteData;
  
        setSnackbar({
          open: true,
          message: "IR data synchronization in progress",
          severity: "info",
        });
        const res = await taxPortalClientApi.taxPortal_InsertTopic(selectedSyncData);
      } catch (error) {
        console.log(error);
      }
      if (report) {
        setTimeout(async () => {
          await report.refresh();
        }, 2000);
      }
    };

  useEffect(() => {
    timeOutForRefreshToken(tokenExpiration, setTokenTimeout, refreshAccessToken);
    return () => {
      if (tokenTimeout) {
        clearTimeout(tokenTimeout);
      }
    };
  }, [report, tokenExpiration]);
  return (
    <>
      <div className="powerBi-container">
        <div className="powerBi-container">
          {flag === true ? (
            <UnAuthPowerBi name="Global Tax Information" />
          ) : (
            <div style={{ position: "relative", width: "95%" }}>
              <PowerBIEmbed
                embedConfig={reportConfigDTO}
                cssClassName="report-style-class"
                getEmbeddedComponent={(embedObject: Embed) => {
                  setReport(embedObject as Report);
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 10,
                  right: 40,
                  cursor: "pointer",
                }}
              >
                {reportConfigDTO.settings !== undefined && (
                  <>
                    <SyncIcon onClick={syncIconClick} style={{ color: "#FFFFFF", fontSize: "35px" }} />
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
