import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Selection,
  Paging,
  SearchPanel,
  Toolbar,
  Item,
  LoadPanel,
} from "devextreme-react/data-grid";
import { dataGridConfig } from "../../../constants";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import { CheckBox } from "devextreme-react/check-box";
import { Button, Tooltip } from "@mui/material";
import {
  MDMLegalEntityDTO,
  MDMLegalEntityResponse,
} from "../../../api-client-nswag/taxportal-client";
import { OidcIdentityContext } from "@rsmus/react-auth";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  getTaxYearResponse,
  getTaxYearResponseForCS,
} from "../SitesWithLegalEntities";
import { getAppName } from "../../../cui/commonComponents/AppNameToggle";

type DatagridRefType = DataGrid;

const SelectLegalEntities = (props: any) => {
  const [currentPageKeys, setCurrentPageKeys] = useState<any[]>([]); 
  const [syncClicked, setSyncClicked] = useState(false);
  const dataGridRef = useRef<DatagridRefType>(null);
  const [hasFalseConsent, setHasFalseConsent] = useState(false);
  const { user } = useContext(OidcIdentityContext);
  const [userCountry, setUserCountry] = useState("");
  const [legalEntityConsentData, setLegalEntityConsentData] =
    useState<MDMLegalEntityDTO[]>();
  const [taxYear] = useRecoilState<any>(getTaxYearResponse);
  const [taxYearForCS] = useRecoilState<any>(getTaxYearResponseForCS);
  const appName = useRecoilValue(getAppName);

  useEffect(() => {
    const fetchData = async () => {
      try {
        props.setLegalData({
          pending: true,
          fulfilled: false,
          gridLegalData: [],
        });
        const yearToCheckConsent =
          ((appName === "TP" || appName === "PERF") && taxYear) ||
          (appName === "CS" && taxYearForCS);
        const data = await taxPortalClientApi.mDM_GetMDMLegalEntities(
          props.selectedRowsData?.mdmMasterClientId,
          yearToCheckConsent
        );
        props.setLegalData({
          pending: false,
          fulfilled: true,
          gridLegalData: data.legalEntites,
        });
        setUserCountry(data?.countryCode!);
        setLegalEntityConsentData(data?.legalEntites!);
      } catch (error) {
        console.log(error, "error");
      }
    };
    fetchData();
    props.setSelectedLegalEntityData([]);
  }, []);

  const masterClientName = props.selectedRowsData?.name;
  const message = `You do not have permissions to create site for ${masterClientName}. All legal entities do not have consent. The site(s) for ${masterClientName} will need to be created by a US-based user.`;

  
  const onSelectionChanged = (e: any) => {
    const visibleRowKeys = e.component.getVisibleRows().map((row: any) => row.key);
      const newlySelectedKeys = e.selectedRowKeys?.filter((key: any) =>
      visibleRowKeys.includes(key)
    );
      const newlyDeselectedKeys = e.deselectedRowKeys?.filter((key: any) =>
      visibleRowKeys.includes(key)
    );
    setCurrentPageKeys(newlySelectedKeys);
  
    console.log(newlySelectedKeys,"newlySelectedKeys");
    props.setSelectedLegalEntityData((prev:any)=>{
      return [...prev,...newlySelectedKeys]
    });
  };
  
  const handleOptionChange = (e: any) => {
    if (e.name === "paging" && e.fullName === "paging.pageIndex") {
      dataGridRef.current?.instance.clearSelection();
      setCurrentPageKeys([]);
    }
  };
  

  const is7216ConsentReceived = (celldata: any) => {
    var consentGiven = false;
    if (celldata.data.consentReceivedYears !== null) {
      const str = celldata.data.consentReceivedYears;
      const arr: number[] = JSON.parse(str);
      var yearToCheckConsent = parseInt(
        ((appName === "TP" || appName === "PERF") && taxYear) ||
          (appName === "CS" && taxYearForCS),
        10
      );
      if (yearToCheckConsent != null)
        consentGiven = arr.includes(yearToCheckConsent);
    } else consentGiven = false;
    return <div>{consentGiven ? "Y" : "N"}</div>;
  };

  useEffect(() => {
    if (userCountry === "IN") {
      var yearToCheckConsent = parseInt(
        ((appName === "TP" || appName === "PERF") && taxYear) ||
          (appName === "CS" && taxYearForCS),
        10
      );
      var hasFalseConsentInEntities = false;

      var isFlag = legalEntityConsentData?.filter((a) => {
        var checkForMixedConsent = a.consentReceivedYears?.includes(
          yearToCheckConsent.toString()
        );
        return a.consentReceivedYears === null || !checkForMixedConsent;
      });

      if (isFlag !== undefined && isFlag?.length > 0) {
        hasFalseConsentInEntities = true;
      } else {
        hasFalseConsentInEntities = false;
      }
      setHasFalseConsent(hasFalseConsentInEntities!);
      if (props.selectionMode === "multiple") {
        if (props.selectedLegalEntityData?.length === 0) {
          props.setBtnContinueDisable(true);
        }
        if (hasFalseConsentInEntities) props.setBtnContinueDisable(true);
        if (
          props.selectedLegalEntityData?.length > 0 &&
          !hasFalseConsentInEntities
        )
          props.setBtnContinueDisable(false);
      }
      if (props.selectionMode === "none") {
        hasFalseConsentInEntities
          ? props.setBtnContinueDisable(true)
          : props.setBtnContinueDisable(false);
        setHasFalseConsent(hasFalseConsentInEntities!);
      }
    } else {
      if (props.selectionMode === "multiple") {
        if (props.selectedLegalEntityData?.length === 0) {
          props.setBtnContinueDisable(true);
        }
        if (props.selectedLegalEntityData?.length > 0) {
          props.setBtnContinueDisable(false);
        }
      }
      if (props.selectionMode === "none") {
        props.setBtnContinueDisable(false);
      }
    }
  }, [
    legalEntityConsentData,
    props.selectedLegalEntityData,
    props.selectionMode,
    userCountry,
    props.legalData,
  ]);

  const refreshButtonOptions = {
    icon: "refresh",
    onClick: async () => {
      const gridInstance = dataGridRef.current!.instance;
      gridInstance.clearSelection();
      var yearToCheckConsent =
        ((appName === "TP" || appName === "PERF") && taxYear) ||
        (appName === "CS" && taxYearForCS);
      const data = await taxPortalClientApi.mDM_GetMDMLegalEntities(
        props.selectedRowsData?.mdmMasterClientId,
        yearToCheckConsent
      );
      if (userCountry === "IN") {
        var isFlag = data.legalEntites?.filter((a) => {
          var checkForMixedConsent = a.consentReceivedYears?.includes(
            yearToCheckConsent.toString()
          );
          return a.consentReceivedYears === null || !checkForMixedConsent;
        });
        var hasFalseConsentInEntities = false;
        if (isFlag !== undefined && isFlag?.length > 0) {
          hasFalseConsentInEntities = true;
        } else {
          hasFalseConsentInEntities = false;
        }
        setHasFalseConsent(hasFalseConsentInEntities);
        setLegalEntityConsentData(data?.legalEntites);
        props.setLegalData({
          pending: false,
          fulfilled: true,
          gridLegalData: data.legalEntites,
        });
      } else {
        props.setLegalData({
          pending: false,
          fulfilled: true,
          gridLegalData: data.legalEntites,
        });
      }
    },
  };
  return (
    <div>
      <div
        style={{
          margin: "0 0 10px 0",
          display: "flex",
          justifyContent: "space-between",
        }}
      ></div>
      <div
        style={{
          paddingBottom: "10px",
          justifyContent: "space-between",
        }}
      >
        {
          "Please select an entity if you are creating a site for a specific entity under the client (Mixed Consent Clients). If you are creating the site for the Master client and including all entities, select 'Always includes all entities' and click 'Next'"
        }
      </div>

      <DataGrid
        ref={dataGridRef}
        {...dataGridConfig}
        showBorders={true}
        height={300}
        defaultSelectedRowKeys={props.selectedLegalEntityData} 
        onSelectionChanged={onSelectionChanged}
        dataSource={props.legalData.gridLegalData}
        noDataText={
          props.legalData.pending === true
            ? "Loading..."
            : syncClicked
            ? "Refresh after sometime"
            : "Sync Legal Entity"
        }
        onOptionChanged={handleOptionChange}
        onEditorPreparing={(e) => {
          if (e.parentType === "searchPanel") {
            e.editorOptions.onValueChanged = (args: any) => {
              const searchValue = args.value?.toLowerCase() || "";
              const grid = e.component;
              grid.filter([
                ["clientId", "contains", searchValue],
                "or",
                ["clientName", "contains", searchValue],
              ]);
            };
          }
        }}
      >
        <Selection mode={props.selectionMode} />
        <Paging defaultPageSize={20} />
        <Scrolling mode="standard" />
        <LoadPanel enabled={false} />
        <SearchPanel placeholder={"Global Search..."} visible />
        <Toolbar>
          <Item location={"before"}>{props.selectedRowsData?.name}</Item>
          <Item>
            <div>
              <Button
                style={{ margin: "0 12px 0 0 " }}
                onClick={async () => {
                  await taxPortalClientApi.mDM_SyncLegalEntitiesConsent(
                    props.selectedRowsData?.mdmMasterClientId,
                    ((appName === "TP" || appName === "PERF") && taxYear) ||
                      (appName === "CS" && taxYearForCS),
                    null
                  );
                  setSyncClicked(true);
                }}
                variant="outlined"
              >
                Sync Legal Entites
              </Button>
              <CheckBox
                value={props.selectionMode === "multiple" ? false : true}
                onValueChanged={(a: any) => {
                  if (a.value) {
                    props.setSelectionMode("none");
                  } else {
                    props.setSelectionMode("multiple");
                  }
                }}
              />
              <Tooltip
                placement="top"
                title="New entities are automatically added to the site when they are associated with this master client."
              >
                <span style={{ margin: "0 12px 0 5px" }}>
                  Always include all legal entites
                </span>
              </Tooltip>
            </div>
          </Item>
          <Item name="searchPanel" location="after" />
          <Item
            widget="dxButton"
            options={refreshButtonOptions}
            name="alignRight"
          />
        </Toolbar>
        <Column
          alignment="left"
          dataField="clientId"
          caption={"Client Id"}
        ></Column>
        <Column
          alignment="left"
          dataField="clientName"
          caption={"Client Name"}
        ></Column>
        <Column
          alignment="left"
          cellRender={is7216ConsentReceived}
          dataField="is7216ConsentReceived"
          caption={"Consent"}
        ></Column>
      </DataGrid>
      {hasFalseConsent === true && (
        <div style={{ color: "red", fontSize: "14px" }}>{message} </div>
      )}
    </div>
  );
};

export default SelectLegalEntities;
